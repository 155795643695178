import {getQueriesList} from 'src/appApi';
import {TaxProfileSubsection} from 'src/constants/constants';
import {SET_QUERIES} from '../actionNames';
import {setAppState} from '../app/app.actions';
import {AppReducerStates, NotificationType} from '../app/app.reducer';
import {
  QueryDocDataType,
  ReduxMessageType,
  ReduxQueryType,
  QueryType,
  DerivedQueryType,
  DerivedMessageType,
  MessageSubType,
  MessageType,
  QuerySubType,
} from './queries.reducer';
import {selectActiveYear} from '../globals/globals.selector';
import {getStateType} from '../store';

const setQueries = (payload: any) => {
  return {
    type: SET_QUERIES,
    payload,
  };
};

export const fetchQueries = ({userId}: {userId: number}) => {
  return async (dispatch: any, getState: getStateType) => {
    const activeYear = selectActiveYear(getState());
    try {
      const queriesData = (
        await getQueriesList({
          fly_user_id: userId,
          app_year: activeYear,
          is_conversation_required: true,
          type: [QueryType.QTC, QueryType.TAX_FILING],
          per_page: 500,
        })
      ).data;
      const queryMap = {} as {[key: number]: ReduxQueryType};
      const messageMap = {} as {[key: number]: ReduxMessageType};

      const taxProfileSubsections = Object.values(TaxProfileSubsection);

      const {count} = queriesData;

      /**
       * Query Mapping
       */
      const queries: ReduxQueryType[] = queriesData.results.map((rawQuery) => {
        const {
          id: queryId,
          query,
          created_at: queryCreatedAt,
          fly_user_id: flyUserId,
          app_year: appYear,
          type: queryType,
          status: queryStatus,
          status_updated_at: queryStatusUpdatedAt,
          call_status: callStatus,
          tax_return_form_section: taxReturnFormSection,
          start_time: startTime,
          end_time: endTime,
          calendly_event_uuid: calendlyEventUuid,
          calendly_invitee_uuid: calendlyInviteeUuid,
          name,
          email,
          created_date: createdDate,
          assignee_email: assigneeEmail,
          conversation,
          doc_data,
          sub_type,
          section: taxProfileSubsection,
          high_value_deductions: highValueDeductions,
          has_user_read,
          biz_return_id,
          assigned_return_type,
        } = rawQuery;

        let derivedQueryType = DerivedQueryType.UNKNOWN;
        if (
          queryType === QueryType.TAX_FILING &&
          [
            QuerySubType.INCOMPLETE_DOC,
            QuerySubType.WRONG_DOC,
            QuerySubType.DOC_NOT_CLEAR,
          ].includes(sub_type)
        ) {
          derivedQueryType = DerivedQueryType.REUPLOAD_DOCUMENT;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.ADDITIONAL_DOC
        ) {
          derivedQueryType = DerivedQueryType.UPLOAD_NEW_DOCUMENT;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.TAX_PROFILE_CPA
        ) {
          derivedQueryType = DerivedQueryType.TAX_PROFILE_CPA;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.HIGH_VALUE_DEDUCTIONS
        ) {
          derivedQueryType = DerivedQueryType.HIGH_VALUE_DEDUCTION;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.TAX_PROFILE
        ) {
          derivedQueryType = DerivedQueryType.TAX_PROFILE;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.GENERAL
        ) {
          derivedQueryType = DerivedQueryType.GENERAL;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.REVIEW_ESIGN
        ) {
          derivedQueryType = DerivedQueryType.REVIEW_ESIGN;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.DISTRIBUTE_DEDUCTIONS
        ) {
          derivedQueryType = DerivedQueryType.DISTRIBUTE_DEDUCTIONS;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.TAX_FILING_FEEDBACK
        ) {
          derivedQueryType = DerivedQueryType.TAX_FILING_FEEDBACK;
        } else if (
          queryType === QueryType.TAX_FILING &&
          sub_type === QuerySubType.PERSONAL_RETURN_ACCEPTED_BY_IRS
        ) {
          derivedQueryType = DerivedQueryType.PERSONAL_RETURN_ACCEPTED_BY_IRS;
        }

        /**
         * Message Mapping
         */
        const messages: ReduxMessageType[] =
          conversation?.map((conversation) => {
            const {
              id: messageId,
              message,
              created_at: messageCreatedAt,
              author_type: authorType,
              type: messageType,
              extra_data: extraData,
              is_draft: isDraft,
              doc_id: docId,
              filename,
              doc_type_id: docTypeId,
              doc_type: docType,
              author_name: authorName,
              sub_type: messageSubType,
            } = conversation;

            let derivedMessageType = DerivedMessageType.NORMAL;
            if (
              messageType === MessageType.INFO &&
              [MessageSubType.REOPENED, MessageSubType.RESOLVED].includes(
                messageSubType,
              )
            ) {
              derivedMessageType = DerivedMessageType.HISTORY;
            } else if (isDraft) {
              derivedMessageType = DerivedMessageType.DRAFT_MESSAGE;
            } else if (messageType === MessageType.DOC_ATTACHED) {
              derivedMessageType = DerivedMessageType.DOC_ATTACHED;
            }

            const mappedMessage: ReduxMessageType = {
              queryId,
              messageId,
              message,
              messageCreatedAt: new Date(messageCreatedAt),
              authorType,
              messageType,
              extraData,
              isDraft,
              docId,
              filename,
              docTypeId,
              docType,
              authorName,
              messageSubType,
              derivedMessageType,
            };
            messageMap[messageId] = mappedMessage;
            return mappedMessage;
          }) ?? [];

        let docData: QueryDocDataType | null = null;

        if (doc_data !== null) {
          const {doc_id, doc_type, doc_type_id, file_name} = doc_data;
          docData = {
            docId: doc_id,
            docType: doc_type,
            docTypeId: doc_type_id,
            fileName: file_name,
          };
        }

        const mappedQuery: ReduxQueryType = {
          queryId,
          query,
          queryCreatedAt,
          flyUserId,
          appYear,
          queryType,
          queryStatus,
          queryStatusUpdatedAt,
          callStatus,
          taxReturnFormSection,
          startTime,
          endTime,
          calendlyEventUuid,
          calendlyInviteeUuid,
          name,
          email,
          createdDate,
          assigneeEmail,
          messages,
          docData,
          querySubtype: sub_type,
          taxProfileSubsection,
          derivedQueryType,
          highValueDeductions,
          hasUserRead: has_user_read,
          biz_return_id,
          assigned_return_type,
        };

        queryMap[queryId] = mappedQuery;
        return mappedQuery;
      });

      dispatch(
        setQueries({
          count,
          queries,
          queryMap,
          messageMap,
        }),
      );
    } catch (e) {
      dispatch(
        setAppState(AppReducerStates.notifcationStatus, {
          isVisible: true,
          message: `Failed to fetch queries (${e})`,
          type: NotificationType.error,
        }),
      );
    }
  };
};
