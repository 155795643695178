import {
  TaxProfileSection,
  TaxProfileSectionToSubsectionMapping,
  TaxProfileSubsection,
} from 'src/constants/constants';
import {
  BusinessDetails,
  BusinessType,
} from 'src/store/businessDetails/businessDetails.reducer';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';

export const getTaxProfileSectionFromSubsection = (
  subsection: TaxProfileSubsection,
) => {
  const sections = Object.keys(TaxProfileSectionToSubsectionMapping);
  let targetSection = 'batman';
  sections.forEach((section: TaxProfileSection) => {
    if (TaxProfileSectionToSubsectionMapping[section].includes(subsection)) {
      targetSection = section;
    }
  });
  return targetSection as TaxProfileSection;
};

export const getFieldValue = (value: any) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return 'NA';
  }
  return value;
};

export const getReturnName = (
  taxReturn: TaxReturn,
  businesses: BusinessDetails[],
) => {
  if (taxReturn.return_type === TaxReturnType.INDIVIDUAL) {
    return 'Individual Return';
  } else if (taxReturn.return_type === TaxReturnType.BUSINESS) {
    const business = businesses.find(
      (data) => data.id === taxReturn.business_id,
    );
    return `Business Return - ${business?.name} (${business?.entityType})`;
  }

  return '';
};

export const shouldShowPaymentDetails = ({
  taxReturn,
  businesses,
}: {
  taxReturn: TaxReturn;
  businesses: BusinessDetails[];
}) => {
  const currBiz = businesses.find((biz) => biz.id === taxReturn.business_id);
  if (taxReturn.return_type === TaxReturnType.BUSINESS) {
    const noPaymentDetailsEntity = [
      BusinessType.S_CORP,
      BusinessType.PARTNERSHIP,
    ];
    if (currBiz && noPaymentDetailsEntity.includes(currBiz.entityType)) {
      return false;
    }
  }

  if (
    taxReturn.return_type === TaxReturnType.INDIVIDUAL ||
    (taxReturn.return_type === TaxReturnType.BUSINESS &&
      currBiz &&
      currBiz.entityType === BusinessType.C_CORP)
  ) {
    const atleastOneRefund = taxReturn.refund_and_due.refunds.log.some(
      (log) => log.amount !== 0,
    );
    const atleastOneDue = taxReturn.refund_and_due.dues.log.some(
      (log) => log.amount !== 0,
    );
    if (!atleastOneRefund && !atleastOneDue) {
      return false;
    }
  }

  return true;
};
