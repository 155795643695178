import React from 'react';
import PartnerSectionHeader from '../common/PartnerSectionHeader';
import {TaxProfileSection} from 'src/constants/constants';

const PartnerIncome = () => {
  return (
    <div style={{display: 'flex', flex: 1, overflow: 'hidden'}}>
      <div
        style={{
          display: 'flex',
          flex: 0.75,
          flexDirection: 'column',
          overflow: 'hidden',
        }}>
        <PartnerSectionHeader
          sectionName="Income"
          section={TaxProfileSection.Income}
        />
      </div>
    </div>
  );
};

export default PartnerIncome;
