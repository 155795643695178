import React, {useMemo, useState} from 'react';
import Box from 'src/DesignSystem/Box/Box';
import LabelField from './LabelField';
import {
  BACKEND_REQUEST_KEYS,
  BACKEND_RESPONSE_KEYS,
  TAX_RETURN_STATUS_LABELS,
} from 'src/CpaCenterList/components/cpaList.utils';
import {TAX_FILING_STATUS, themmeColor} from 'src/constants/constants';
import {
  TaxReturn,
  TaxReturnType,
} from 'src/store/taxReturns/taxReturns.reducer';
import ExtraCountCell from './ExtraCountCell';
import MultiReturnStatusUpdatePopup from './MultiReturnStatusUpdatePopUp';
import UpdateReturnStatus from './UpdateReturnStatus';
import {stringClipper} from 'src/common/utils';
import {selectTaxReturnsReducer} from 'src/store/taxReturns/taxReturns.selector';
import {useDispatch, useSelector} from 'react-redux';
import {patchTaxFiling} from 'src/appApi';
import {fetchUserInfo} from 'src/store/user/user.actions';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import useCurrentUserId from 'src/CpaCenterV2/hooks/useCurrentUserId';
import {useActiveYear} from 'src/common/hooks/useActiveYear';

interface ReturnStatusUpdateProps {
  loading: boolean;
  setLoading: (newState: boolean) => void;
}

const ReturnStatusUpdate = ({
  loading,
  setLoading,
}: ReturnStatusUpdateProps) => {
  const [multiReturnStatusPopUp, setMultiReturnStatusPopUp] = useState(false);
  const [selectedReturn, setSelectedReturn] = useState<{
    returnId: number;
    returnType: TaxReturnType;
  } | null>(null);
  const {taxReturns, loaded: taxReturnsLoaded} = useSelector(
    selectTaxReturnsReducer,
  );
  const [taxFilingStatusPopUp, setTaxFilingStatusPopUp] = useState(false);
  const dispatch = useDispatch();

  const {userId} = useCurrentUserId();
  const {activeYear} = useActiveYear();

  const taxReturnDetails = useMemo(() => {
    const businessReturn = taxReturns.find(
      (taxReturn) => taxReturn.return_type === TaxReturnType.BUSINESS,
    );
    const indReturn = taxReturns.find(
      (taxReturn) => taxReturn.return_type === TaxReturnType.INDIVIDUAL,
    );
    const indReturnData = indReturn
      ? {
          return_type: indReturn.return_type,
          return_id: indReturn.return_id,
          name: 'Individual',
          status: indReturn.cpa_status,
        }
      : {};
    const bizReturnData = taxReturns
      .filter((taxReturn) => taxReturn.return_type === TaxReturnType.BUSINESS)
      .map((taxReturn) => {
        {
          return {
            return_id: taxReturn.return_id,
            return_type: taxReturn.return_type,
            name: taxReturn.business_name ?? '',
            status: taxReturn.cpa_status,
          };
        }
      });
    const multiReturnStatusUpdateData = [indReturnData, ...bizReturnData];
    return {
      businessReturn: businessReturn,
      indReturn: indReturn,
      multiReturnStatusUpdateData: multiReturnStatusUpdateData,
    };
  }, [taxReturns]);
  const totalReturns = taxReturns.length;

  const getSelectedReturnStatus = () => {
    return (
      taxReturnDetails.multiReturnStatusUpdateData.filter((data) => {
        return (
          data.return_id === selectedReturn?.returnId &&
          data.return_type === selectedReturn?.returnType
        );
      })[0]?.status ?? TAX_FILING_STATUS.TAX_PROFILE_SUBMITTED
    );
  };
  const refreshInfo = async () => {
    await dispatch(fetchUserInfo(userId, activeYear));
    await dispatch(fetchTaxReturns(userId, activeYear));
  };

  const onUpdateTaxFilingStatus = async (
    returnId: number,
    returnType: TaxReturnType,
    newId: TAX_FILING_STATUS,
  ) => {
    try {
      setLoading(true);
      if (returnType === TaxReturnType.INDIVIDUAL) {
        await patchTaxFiling(
          {tax_filing_id: returnId},
          {[BACKEND_RESPONSE_KEYS.STATUS]: newId},
        );
      } else {
        await patchTaxFiling(
          {tax_filing_id: returnId},
          {
            [BACKEND_REQUEST_KEYS.CPA_STATUS]: newId,
            [BACKEND_REQUEST_KEYS.RETURN_TYPE]: TaxReturnType.BUSINESS,
          },
        );
      }
    } catch (e) {
      alert('Failed to update Return status');
    } finally {
      await refreshInfo();
      setLoading(false);
      setTaxFilingStatusPopUp(false);
    }
  };

  const getSelectedReturnName = () => {
    return (
      taxReturnDetails.multiReturnStatusUpdateData.filter((data) => {
        return (
          data.return_id === selectedReturn?.returnId &&
          data.return_type === selectedReturn?.returnType
        );
      })[0]?.name ?? ''
    );
  };

  return (
    <div>
      <Box
        backgroundColor="#0D0F0F0D"
        style={{
          borderColor: themmeColor.silver,
          gap: '8px',
          padding: 12,
          alignItems: 'center',
        }}>
        {taxReturnDetails.indReturn && (
          <LabelField
            label="PERSONAL (RS)"
            value={
              TAX_RETURN_STATUS_LABELS[taxReturnDetails.indReturn?.cpa_status]
                ?.label
            }
            onClick={() => {
              setTaxFilingStatusPopUp(true);
              setSelectedReturn({
                returnId: taxReturnDetails.indReturn?.return_id,
                returnType: TaxReturnType.INDIVIDUAL,
              });
            }}
            backgroundColor={
              TAX_RETURN_STATUS_LABELS[taxReturnDetails.indReturn?.cpa_status]
                ?.backColor
            }
            valueColor={
              TAX_RETURN_STATUS_LABELS[taxReturnDetails.indReturn?.cpa_status]
                ?.textColor
            }
          />
        )}
        {taxReturnDetails.businessReturn && (
          <LabelField
            label={stringClipper(
              taxReturnDetails.businessReturn.business_name ??
                'Business return',
              12,
            )}
            value={
              TAX_RETURN_STATUS_LABELS[
                taxReturnDetails.businessReturn.cpa_status
              ]?.label
            }
            onClick={() => {
              setTaxFilingStatusPopUp(true);
              setSelectedReturn({
                returnId: taxReturnDetails.businessReturn?.return_id,
                returnType: TaxReturnType.BUSINESS,
              });
            }}
            backgroundColor={
              TAX_RETURN_STATUS_LABELS[
                taxReturnDetails.businessReturn?.cpa_status
              ]?.backColor
            }
            valueColor={
              TAX_RETURN_STATUS_LABELS[
                taxReturnDetails.businessReturn?.cpa_status
              ]?.textColor
            }
          />
        )}
        {totalReturns > 2 && (
          <ExtraCountCell
            onClick={() => setMultiReturnStatusPopUp(true)}
            value={totalReturns - 2}
          />
        )}
      </Box>
      {selectedReturn && (
        <UpdateReturnStatus
          key={`returnStatus-${selectedReturn?.returnId}-${taxFilingStatusPopUp}`}
          isOpen={taxFilingStatusPopUp}
          onClose={() => setTaxFilingStatusPopUp(false)}
          returnName={getSelectedReturnName()}
          returnId={selectedReturn?.returnId}
          returnType={selectedReturn?.returnType}
          currentReturnStatusId={getSelectedReturnStatus()}
          loading={loading}
          onUpdate={onUpdateTaxFilingStatus}
        />
      )}
      {taxReturnsLoaded && (
        <MultiReturnStatusUpdatePopup
          key={`multiReturnStatusPopup-${multiReturnStatusPopUp}`}
          isOpen={multiReturnStatusPopUp}
          onClose={() => setMultiReturnStatusPopUp(false)}
          dataArray={taxReturnDetails.multiReturnStatusUpdateData}
          setTaxFilingStatusPopUp={setTaxFilingStatusPopUp}
          setSelectedReturn={setSelectedReturn}
        />
      )}
    </div>
  );
};

export default ReturnStatusUpdate;
