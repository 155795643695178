import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {
  COMMON_COMPONENTS_ID,
  TaxProfileSection,
  themmeColor,
} from 'src/constants/constants';
import {CpaCenterV2RouteParts} from 'src/constants/routeName';
import Steps from 'src/DesignSystem/Steps/Steps';
import {fetchApplicableDeductions} from 'src/store/applicableDeductions/applicableDeductions.actions';
import {fetchBusinessDetails} from 'src/store/businessDetails/businessDetails.actions';
import {fetchCCHStatus} from 'src/store/cchStatus/cchStatus.actions';
import {resetRedux} from 'src/store/common.actions';
import {fetchDeductionSummary} from 'src/store/deductionSummary/deductionSummary.actions';
import {fetchDocumentsForGivenYear} from 'src/store/documents/documents.actions';
import {fetchHighValueDeductions} from 'src/store/highValueDeductions/highValueDeductions.actions';
import {fetchQueries} from 'src/store/queries/queries.actions';
import {fetchTaxFormsForGivenYear} from 'src/store/taxForms/taxForms.actions';
import {fetchTaxProfileForGivenYear} from 'src/store/taxProfile/taxProfile.actions';
import {fetchTaxProfileStatusForGivenYear} from 'src/store/taxProfileStatus/taxProfileStatus.actions';
import {fetchTaxProfileStatesInfo} from 'src/store/taxProfileStatesInfo/taxProfileStatesInfo.actions';
import {fetchTransactions} from 'src/store/transactions/transactions.actions';
import {fetchUserInfo} from 'src/store/user/user.actions';
import CreateQueryDrawer from './common/CreateQueryDrawer/CreateQueryDrawer';
import TaxFormDrawer from './common/TaxFormDrawer/TaxFormDrawer';
import TaxProfileSummaryDrawer from './common/TaxProfileSummary/TaxProfileSummaryDrawer';
import UserSummaryBar from './common/UserSummaryBar/UserSummaryBar';
import DocumentsReview from './DocumentsReview/DocumentsReview';
import useCurrentStep from './hooks/useCurrentStep';
import useCurrentUserId from './hooks/useCurrentUserId';
import useLayout from './hooks/useLayout';
import TaxReturns from './TaxReturns/TaxReturns';
import TaxReturnDetailedView from './TaxReturnDetailedView/TaxReturnDetailedView';
import SentToCCH from './SentToCCH/SentToCCH';
import TaxProfileReview from './TaxProfileReview/TaxProfileReview';
import BasicDetails from './TaxProfileSections/BasicDetails/BasicDetails';
import Credits from './TaxProfileSections/Credits/Credits';
import HighValueDeductionsStatusDrawer from './TaxProfileSections/Deductions/components/HighValueDeductionsStatusDrawer';
import Deductions from './TaxProfileSections/Deductions/Deductions';
import Income from './TaxProfileSections/Income/Income';
import StatesInfoDrawer from './TaxProfileSections/StateTaxes/components/StatesInfoDrawer';
import StateTaxes from './TaxProfileSections/StateTaxes/StateTaxes';
import TaxPayments from './TaxProfileSections/TaxPayments/TaxPayments';
import UserQueries from './UserQueries/UserQueries';
import ReuploadDocumentQueryDrawer from './ViewDocument/components/ReuploadDocumentQueryDrawer';
import ViewDocument from './ViewDocument/ViewDocument';
import {fetchTaxReturns} from 'src/store/taxReturns/taxReturns.actions';
import {fetchThreads} from 'src/store/threads/threads.actions';
import {fetchEfileLogs} from 'src/store/efileLogs/efileLogs.actions';
import DistributeDeductions from './TaxProfileSections/Deductions/DistributeDeductions/DistributeDeductions';
import DistributeDeductionsQueryDrawer from './TaxProfileSections/Deductions/DistributeDeductions/DistributeDeductionsQueryDrawer';
import {fetchDistributeDeductions} from 'src/store/distributeDeductions/distributeDeductions.actions';
import FileExtensionDetailsDrawer from './common/FileExtensionDetailsDrawer/FileExtensionDetailsDrawer';
import {useActiveYear} from 'src/common/hooks/useActiveYear';
import ChildCareExpenseDrawer from './TaxProfileSections/Credits/components/ChildCareExpenseDrawer';

const CpaCenterV2 = () => {
  const {activeYear} = useActiveYear();
  const {userId} = useCurrentUserId();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo(userId));
    dispatch(fetchTaxProfileForGivenYear(userId, activeYear));
    dispatch(fetchBusinessDetails(userId));
    dispatch(fetchDocumentsForGivenYear(userId, activeYear));
    dispatch(fetchQueries({userId}));
    dispatch(fetchTaxFormsForGivenYear(userId, activeYear));
    dispatch(fetchTransactions(userId));
    dispatch(fetchTaxProfileStatusForGivenYear(userId, activeYear));
    dispatch(fetchTaxProfileStatesInfo(userId));
    dispatch(fetchDeductionSummary(userId));
    dispatch(fetchCCHStatus(userId));
    dispatch(fetchHighValueDeductions(userId));
    dispatch(fetchApplicableDeductions(userId));
    dispatch(fetchTaxReturns(userId));
    dispatch(fetchThreads(userId));
    dispatch(fetchEfileLogs(userId));
    dispatch(fetchDistributeDeductions(userId));
    return () => {
      dispatch(resetRedux());
    };
  }, [userId, activeYear]);

  const {steps, isLoading} = useCurrentStep();

  const taxProfileSections = [
    {
      section: TaxProfileSection.BasicDetails,
      Component: BasicDetails,
    },
    {
      section: TaxProfileSection.Income,
      Component: Income,
    },
    {
      section: TaxProfileSection.Deductions,
      Component: Deductions,
    },
    {
      section: TaxProfileSection.Credits,
      Component: Credits,
    },
    {
      section: TaxProfileSection.TaxPayments,
      Component: TaxPayments,
    },
    {
      section: TaxProfileSection.StateTaxes,
      Component: StateTaxes,
    },
    {
      section: CpaCenterV2RouteParts.DistributeDeductions,
      Component: DistributeDeductions,
    },
  ];

  const {spaceLeftBelowUserSummaryBar} = useLayout();

  return (
    <div>
      <UserSummaryBar />
      <div
        style={{
          display: 'flex',
          backgroundColor: themmeColor.cpaCenterV2Bg,
          width: '100%',
          height: spaceLeftBelowUserSummaryBar,
          paddingLeft: 8,
          flexDirection: 'column',
          paddingTop: 20,
        }}>
        <div id={COMMON_COMPONENTS_ID.CPA_CENTER_V2_TAX_FILING_STEPS}>
          <Steps
            data={steps}
            onClick={(step) => {
              navigate(step.props.section);
            }}
            isLoading={isLoading}
          />
        </div>
        <Routes>
          <Route
            path={`/${CpaCenterV2RouteParts.DocumentReview}/${CpaCenterV2RouteParts.DocumentId}`}
            element={<ViewDocument />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.DocumentReview}`}
            element={<DocumentsReview />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.TaxProfileReview}`}
            element={<TaxProfileReview />}
          />
          {taxProfileSections.map(({section, Component}) => (
            <Route
              path={`/${CpaCenterV2RouteParts.TaxProfileReview}/${section}`}
              element={<Component />}
            />
          ))}
          <Route
            path={`/${CpaCenterV2RouteParts.UserQueries}/${CpaCenterV2RouteParts.QueryId}`}
            element={<UserQueries />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.UserQueries}`}
            element={<UserQueries />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.CCH}`}
            element={<SentToCCH />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.ReviewEsign}/${CpaCenterV2RouteParts.ReturnId}/${CpaCenterV2RouteParts.TaxReturnSection}/${CpaCenterV2RouteParts.QueryId}`}
            element={<TaxReturnDetailedView />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.ReviewEsign}/${CpaCenterV2RouteParts.ReturnId}/${CpaCenterV2RouteParts.TaxReturnSection}`}
            element={<TaxReturnDetailedView />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.ReviewEsign}/${CpaCenterV2RouteParts.ReturnId}`}
            element={<TaxReturnDetailedView />}
          />
          <Route
            path={`/${CpaCenterV2RouteParts.ReviewEsign}`}
            element={<TaxReturns />}
          />
        </Routes>
        <CreateQueryDrawer />
        <TaxProfileSummaryDrawer />
        <TaxFormDrawer />
        <StatesInfoDrawer />
        <HighValueDeductionsStatusDrawer />
        <ReuploadDocumentQueryDrawer />
        <DistributeDeductionsQueryDrawer />
        <FileExtensionDetailsDrawer />
        <ChildCareExpenseDrawer />
      </div>
    </div>
  );
};

export default CpaCenterV2;
