import {
  VALID_DOCUMENT_STATUS,
  themmeColor,
  DOCUMENT_TYPES,
} from 'src/constants/constants';
import {TaxFormType} from 'src/store/taxForms/taxForms.types';
import {Typography} from '@mui/material';
import {
  addTaxForm,
  deleteTaxForm,
  getDocumentOcrData,
  populateBusinessDetailsFromDoc,
  updateTaxForm,
} from 'src/appApi';
import React, {useContext, useEffect, useState} from 'react';
import FullScreenLoading from 'src/common/FullScreenLoading';
import {
  FormType,
  OCR_STATUS,
  backendFormsResponseMapper,
} from 'src/CpaCenterUserInfo/CpaCenterDocumentsOCR/CpaCenterDocumentOCR.utils';
import {makeStyles} from '@mui/styles';
import Form from './Form';
import ViewDocumentContext from '../ViewDocument.context';
import DSButton from 'src/DesignSystem/Button/Button';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import Dropdown from 'src/DesignSystem/Dropdown/Dropdown';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import {NotificationType} from 'src/store/app/app.reducer';
import SimpleKeyValueField from 'src/CpaCenterV2/TaxProfileSections/common/SimpleKeyValueField';
import {Form as DSForm} from 'src/DesignSystem/Form/Form';
import Box from 'src/DesignSystem/Box/Box';
import AssignBusinessToDoc from './AssignBusinessToDoc';
import {isNumber} from 'lodash';
import {isPartnership} from 'src/CpaCenterV2/common/CpaCenterV2.utils';

const useStyles = makeStyles({
  header: {
    padding: '10px',
    background: ({status}) =>
      status === VALID_DOCUMENT_STATUS.ACCEPTED
        ? themmeColor.offWhite
        : themmeColor.successGreen,
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    height: '16px',
    color: themmeColor.grey,
    alignSelf: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  addForm: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  reviewComplete: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin: '10px 0px',
  },
});

interface RenderOcrDataProps {
  showAssignBusiness?: boolean;
}

const RenderOcrData = ({showAssignBusiness = false}: RenderOcrDataProps) => {
  const {
    userId: flyfinUserId,
    refreshDocuments: fetchDocuments,
    document,
    isImage,
    documentsLoading,
  } = useContext(ViewDocumentContext);
  const [forms, setForms] = useState<{[id: number]: FormType}>({});
  const [ocrStatus, setOcrStatus] = useState<string>('');
  const [addTaxFormPopuo, setAddTaxFormPopup] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState<string | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [seenForms, setSeenForms] = useState(new Set());
  const [showOnlyPriorityFields, setShowOnlyPriorityFields] = useState(false);

  const [selectedBusiness, setSelectedBusiness] = useState(
    document?.linkedBusinessId,
  );

  useEffect(() => {
    setSelectedBusiness(document?.linkedBusinessId);
  }, [document?.linkedBusinessId]);

  const {notify} = useNotify();

  const updateSeenForms = (formId: number) => {
    setSeenForms((prev) => {
      prev.add(formId);
      const newSet = new Set(prev);
      return newSet;
    });
  };

  const getForms = async () => {
    try {
      setLoading(true);
      const res = await getDocumentOcrData({
        fly_user_id: flyfinUserId,
        tfd_info_id: document.docId,
      });
      if (res.data.ocr_status === OCR_STATUS.DONE) {
        const formData = backendFormsResponseMapper(res.data.forms);
        setForms(formData);
      }
      setOcrStatus(res.data.ocr_status);
    } catch (e) {
      notify(`Failed to fetch OCR data ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!documentsLoading) {
      getForms();
    }
  }, [document, documentsLoading]);

  const addForm = async () => {
    try {
      setLoading(true);
      const res = await addTaxForm({
        fly_user_id: flyfinUserId,
        tfd_info_id: document.docId,
        form_type: selectedDocType,
      });
      const formData = backendFormsResponseMapper([res.data]);
      setForms((prev) => {
        return {...prev, ...formData};
      });
      setAddTaxFormPopup(false);
      notify('Added tax form successfully', NotificationType.success);
    } catch (e) {
      notify(`Error Occurred ${e}`, NotificationType.error);
    } finally {
      setLoading(false);
    }
  };

  const handleFormDelete = async (formId: number) => {
    try {
      await deleteTaxForm({tax_form_id: formId});
      setForms((prev) => {
        const prevState = prev;
        delete prevState[formId];
        return {...prevState};
      });
      notify(`Tax form deleted successfully`, NotificationType.success);
    } catch (e) {
      notify(`Failed to delete tax form ${e}`, NotificationType.error);
    }
  };

  const handleFormValueChange = (
    formId: number,
    valueIndex: number,
    fieldValueIndex: number,
    value: any,
    fieldKey: string,
  ) => {
    setForms((prev) => {
      let currForm = prev[formId];
      let currFormField = currForm.form_data[fieldKey];

      if (valueIndex !== -1) {
        currFormField.field_values[fieldValueIndex].value[valueIndex] = value;
      } else {
        currFormField.field_values[fieldValueIndex].value = value;
      }

      currForm[fieldKey] = currFormField;

      return {
        ...prev,
        [formId]: currForm,
      };
    });
  };

  const saveDocumentData = async (isReviewed: boolean) => {
    try {
      setLoading(true);
      const formDataToSend = Object.keys(forms).map((formId) => {
        let formOcrData = {};
        Object.keys(forms[Number(formId)].form_data).forEach((fieldKey) => {
          formOcrData[fieldKey] = {
            field_values:
              forms[Number(formId)].form_data[fieldKey].field_values,
          };
        });
        return {
          id: Number(formId),
          form_data: formOcrData,
        };
      });

      await updateTaxForm({
        fly_user_id: flyfinUserId,
        tfd_info_id: document.docId,
        forms: formDataToSend,
      });
      if (isNumber(selectedBusiness) && isPartnership()) {
        await populateBusinessDetailsFromDoc({
          tfd_doc_id: document.docId,
          business_id: selectedBusiness,
        });
      }
      await fetchDocuments();
      notify(
        isReviewed ? 'Document data saved' : 'Document review complete',
        NotificationType.success,
      );
    } catch (e) {
      notify(
        `${
          isReviewed ? 'Failed to save data' : 'Failed to mark review complete'
        } ${e}`,
        NotificationType.error,
      );
    } finally {
      setLoading(false);
    }
  };

  const styles = useStyles({status: document?.docStatus});

  if (documentsLoading) {
    return <div></div>;
  }

  const isReviewComplete = [
    VALID_DOCUMENT_STATUS.ACCEPTED,
    VALID_DOCUMENT_STATUS.REVIEWED_OCR_DATA,
  ].includes(document.docStatus);

  return (
    <>
      {isLoading && <FullScreenLoading open={isLoading} />}
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className={styles.content}>
          {isReviewComplete && (
            <div
              style={{
                backgroundColor: themmeColor.successGreen,
                padding: 16,
              }}>
              <Typography style={{fontSize: 20, fontWeight: 600}}>
                Review complete
              </Typography>
            </div>
          )}
          {showAssignBusiness && (
            <AssignBusinessToDoc
              docType={document.docType}
              selectedBusiness={selectedBusiness}
              setSelectedBusiness={setSelectedBusiness}
            />
          )}
          <Box
            borderColor={themmeColor.offWhite}
            style={{padding: 16, marginBottom: 12, marginLeft: 8}}>
            <DSForm.SwitchField
              title="Show only priority fields"
              onChangeValue={setShowOnlyPriorityFields}
              value={showOnlyPriorityFields}
            />
          </Box>
          {ocrStatus === OCR_STATUS.IN_PROGRESS ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              OCR IS IN PROGRESS....
            </div>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column'}}>
              {document.docType === DOCUMENT_TYPES.OTHER && (
                <div>
                  <Typography fontSize={20} fontWeight={600}>
                    Additional document
                  </Typography>
                  <div
                    style={{
                      borderRadius: 12,
                      borderWidth: 4,
                      borderColor: '#F0F0F0',
                      backgroundColor: themmeColor.offGrey,
                      padding: 12,
                      marginTop: 12,
                      marginBottom: 12,
                    }}>
                    <SimpleKeyValueField
                      name="Note from user"
                      value={document.extras?.note}
                      loading={documentsLoading}
                    />
                  </div>
                </div>
              )}
              <div>
                {Object.keys(forms).map((formId, index) => {
                  return (
                    <div style={{paddingLeft: 8, paddingBottom: 4}}>
                      <Form
                        form={forms[Number(formId)]}
                        isImage={isImage}
                        key={formId}
                        setForms={setForms}
                        setLoading={setLoading}
                        handleDelete={handleFormDelete}
                        handleFormValueChange={handleFormValueChange}
                        isSeen={seenForms.has(Number(formId))}
                        updateSeenForms={updateSeenForms}
                        index={index}
                        isReviewComplete={isReviewComplete}
                        showOnlyPriorityFields={showOnlyPriorityFields}
                      />
                    </div>
                  );
                })}
              </div>

              <DSButton
                style={{margin: 12}}
                type="secondary"
                text="Add tax-form"
                disabled={isLoading || isReviewComplete}
                onClick={() => setAddTaxFormPopup(true)}
              />
              <DSButton
                style={{margin: 12}}
                type="primary"
                text={isReviewComplete ? 'Save' : 'Review complete'}
                disabled={
                  Object.keys(forms).some(
                    (formId) => !seenForms.has(Number(formId)),
                  ) || isLoading
                }
                onClick={() => saveDocumentData(isReviewComplete)}
              />
            </div>
          )}
        </div>
      </div>
      <PopUp
        isOpen={addTaxFormPopuo}
        primaryButtonTitle="Confirm"
        primaryButtonOnClick={addForm}
        primaryButtonDisabled={isLoading || !selectedDocType}
        secondaryButtonTitle="Cancel"
        style={{width: 400}}
        onClose={() => setAddTaxFormPopup(false)}
        secondaryButtonOnClick={() => setAddTaxFormPopup(false)}
        secondaryButtonDisabled={isLoading}>
        <Typography style={{fontWeight: 600, fontSize: 20, marginBottom: 12}}>
          Add Tax form
        </Typography>
        <Dropdown
          options={Object.values(TaxFormType)}
          onChange={(newOption) => setSelectedDocType(newOption)}
          disabled={isLoading}
          maxWidth
          placeholder="Select document type"
          value={selectedDocType}
        />
      </PopUp>
    </>
  );
};

export default RenderOcrData;
