import React, {useState} from 'react';
import {rephraseTextWithGPT} from 'src/appApi';
import {themmeColor} from 'src/constants/constants';
import DSButton from 'src/DesignSystem/Button/Button';
import useNotify from 'src/DesignSystem/Notify/useNotify';
import RichTextEditor from 'src/DesignSystem/RichTextEditor/RichTextEditor';
import AiIcon from 'src/icons/AiIcon';
import SendIcon from 'src/icons/SendIcon';
import {NotificationType} from 'src/store/app/app.reducer';
import RephrasedTextPreview from './RephrasedTextPreview';
import PlanNameTag from './components/PlanNameTag';
import UploadDocumentIcon from 'src/icons/UploadDocumentIcon';
import ReplyIcon from 'src/icons/ReplyIcon';
import MultiDocumentUpload from '../UploadDocument/MultiDocumentUpload';
import PopUp from 'src/DesignSystem/PopUp/PopUp';
import {Typography} from '@mui/material';
import CPAMessageTemplates from './components/CPAMessageTemplates';

enum MessageTextBoxInputMode {
  SEND_MESSAGE = 'SEND_MESSAGE',
  EDIT_DRAFT = 'EDIT_DRAFT',
  SEND_MESSAGE_WITH_NO_DRAFT = 'SEND_MESSAGE_WITH_NO_DRAFT',
}

interface MessageInputCommonProps {
  isLoading: boolean;
  message: string;
  setMessage: (newString: string) => void;
  height: number;
  placeholder: string;
  inputMode: MessageTextBoxInputMode;
  files?: any[];
  setFiles?: (newFiles: any[]) => any;
}

interface MessageInputSendMessageProps {
  inputMode: MessageTextBoxInputMode.SEND_MESSAGE;
  onSend: () => void;
  onDraft: () => void;
  sendDisabled?: boolean;
  draftDisabled?: boolean;
}

interface MessageInputEditDraftMode {
  inputMode: MessageTextBoxInputMode.EDIT_DRAFT;
  onDoneEditing: () => void;
}

interface MessageInputSendMessageNoDraftProps {
  inputMode: MessageTextBoxInputMode.SEND_MESSAGE_WITH_NO_DRAFT;
  onSend: () => void;
  sendDisabled?: boolean;
}

type MessageBoxProps = MessageInputCommonProps &
  (
    | MessageInputSendMessageProps
    | MessageInputEditDraftMode
    | MessageInputSendMessageNoDraftProps
  );

const MessageTextBox = ({
  placeholder,
  height,
  message,
  setMessage,
  isLoading,
  files,
  setFiles,
  ...props
}: MessageBoxProps) => {
  const [isRephrasing, setIsRephrasing] = useState(false);
  const [filesPopup, setFilesPopup] = useState(false);
  const [cpaQuestionTemplatePopup, setCpaQuestionTemplatePopup] =
    useState(false);
  const [rephrasedMessage, setRephrasedMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const {notify} = useNotify();

  const onClickRephrase = async () => {
    try {
      setIsRephrasing(true);
      const {data} = await rephraseTextWithGPT({message});
      setRephrasedMessage(data);
      setIsVisible(true);
    } catch (e) {
      notify(`Failed to rephrase ${e}`, NotificationType.error);
    } finally {
      setIsRephrasing(false);
    }
  };

  const getMessageInputBottomActions = () => {
    switch (props.inputMode) {
      case MessageTextBoxInputMode.SEND_MESSAGE:
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <DSButton
              text="Draft"
              onClick={props.onDraft}
              disabled={
                isLoading || !message || isRephrasing || props.draftDisabled
              }
              type="secondary"
              style={{marginRight: 8}}
            />
            <DSButton
              text="Send"
              onClick={props.onSend}
              disabled={
                isLoading || !message || isRephrasing || props.sendDisabled
              }
              type="primary"
              endIcon={<SendIcon />}
            />
          </div>
        );
      case MessageTextBoxInputMode.SEND_MESSAGE_WITH_NO_DRAFT:
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <DSButton
              text="Send"
              onClick={props.onSend}
              disabled={
                isLoading || !message || isRephrasing || props.sendDisabled
              }
              type="primary"
              endIcon={<SendIcon />}
            />
          </div>
        );
      case MessageTextBoxInputMode.EDIT_DRAFT:
        return (
          <DSButton
            text="Done editing"
            onClick={props.onDoneEditing}
            disabled={isLoading || !message || isRephrasing}
            type="primary"
          />
        );
    }
  };

  const isRephraseDisabled = isLoading || isRephrasing || !message;
  return (
    <>
      <div
        style={{
          backgroundColor: themmeColor.cpaCenterV2Bg,
          border: 'solid 1px silver',
          borderRadius: 12,
        }}>
        <div style={{position: 'relative'}}>
          <RichTextEditor
            message={message}
            setMessage={setMessage}
            disabled={isLoading || isRephrasing}
            placeholder={placeholder}
            height={height}
          />
          {message?.length <= 20 && <PlanNameTag />}
          <div
            onClick={() => {
              setCpaQuestionTemplatePopup(true);
            }}
            style={{position: 'absolute', top: 4, left: 132}}>
            <ReplyIcon />
          </div>
          {files && setFiles && (
            <div
              onClick={() => {
                setFilesPopup(true);
              }}
              style={{position: 'absolute', top: 4, right: 4}}>
              <UploadDocumentIcon />
              {files?.length > 0 && (
                <div
                  style={{
                    backgroundColor: themmeColor.errorRed,
                    borderRadius: 12,
                    paddingInline: 4,
                    position: 'absolute',
                    right: -6,
                    top: -6,
                  }}>
                  <Typography style={{fontSize: 13, color: themmeColor.white}}>
                    {files.length}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </div>
        {isVisible && (
          <RephrasedTextPreview
            rephrasedMessage={rephrasedMessage}
            setIsVisible={setIsVisible}
            isLoading={isLoading || isRephrasing}
            setMessage={setMessage}
          />
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 52,
            paddingInline: 8,
          }}>
          <DSButton
            onClick={onClickRephrase}
            text="Rephrase"
            startIcon={<AiIcon />}
            disabled={isRephraseDisabled}
            type="secondary"
          />

          {getMessageInputBottomActions()}
        </div>
      </div>
      {files && setFiles && (
        <PopUp
          style={{
            width: 600,
            height: 400,
          }}
          isOpen={filesPopup}
          onClose={() => {
            setFilesPopup(false);
          }}>
          <MultiDocumentUpload files={files} setFiles={setFiles} />
        </PopUp>
      )}
      {cpaQuestionTemplatePopup && (
        <CPAMessageTemplates
          setMessage={setMessage}
          cpaQuestionTemplatePopup
          setCpaQuestionTemplatePopup={setCpaQuestionTemplatePopup}
        />
      )}
    </>
  );
};

export default Object.assign(MessageTextBox, {MessageTextBoxInputMode});
