import _ from 'lodash';
import {
  emptyCCorpFederalTaxReturnSumary,
  emptyCCorpStateTaxReturnSumary,
  emptyIndividualTaxReturnSumary,
  emptyPartnershipFederalTaxReturnSumary,
  emptyPartnershipSCorpStateTaxReturnSummary,
  emptySCorpFederalTaxReturnSumary,
} from './ReviewSummary.constants';
import {
  RETURN_SUMMARY_TYPE,
  ReturnSummaryInputFieldProps,
  ReturnSummaryInputType,
} from './ReviewSummary.types';
import {TaxReturnType} from 'src/store/taxReturns/taxReturns.reducer';
import {BusinessType} from 'src/store/businessDetails/businessDetails.reducer';

export const getEmptyTaxReturnSummary = (
  returnSummaryType: RETURN_SUMMARY_TYPE,
): typeof emptyIndividualTaxReturnSumary => {
  let _emptyTaxReturnSummary = {};
  switch (returnSummaryType) {
    case RETURN_SUMMARY_TYPE.INDIVIDUAL:
      _emptyTaxReturnSummary = _.cloneDeep(emptyIndividualTaxReturnSumary);
      break;
    case RETURN_SUMMARY_TYPE.C_CORP_FEDERAL:
      _emptyTaxReturnSummary = _.cloneDeep(emptyCCorpFederalTaxReturnSumary);
      break;
    case RETURN_SUMMARY_TYPE.C_CORP_STATE:
      _emptyTaxReturnSummary = _.cloneDeep(emptyCCorpStateTaxReturnSumary);
      break;
    case RETURN_SUMMARY_TYPE.S_CORP_FEDERAL:
      _emptyTaxReturnSummary = _.cloneDeep(emptySCorpFederalTaxReturnSumary);
      break;
    case RETURN_SUMMARY_TYPE.PARTNERSHIP_FEDERAL:
      _emptyTaxReturnSummary = _.cloneDeep(
        emptyPartnershipFederalTaxReturnSumary,
      );
      break;
    case RETURN_SUMMARY_TYPE.S_CORP_PARTNERSHIP_STATE:
      _emptyTaxReturnSummary = _.cloneDeep(
        emptyPartnershipSCorpStateTaxReturnSummary,
      );
      break;
    default:
      _emptyTaxReturnSummary = _.cloneDeep(emptyIndividualTaxReturnSumary);
      break;
  }
  return _emptyTaxReturnSummary;
};

export const mapBackendResponseOnEmptyForm = (
  taxReturnSummary: {
    [key: string]: any;
  },
  returnSummaryType: RETURN_SUMMARY_TYPE,
) => {
  const emptyIndividualTaxReturnSumary =
    getEmptyTaxReturnSummary(returnSummaryType);
  Object.entries(taxReturnSummary).forEach(([key, value]: any) => {
    emptyIndividualTaxReturnSumary.forEach((field) => {
      if (
        field.type === ReturnSummaryInputType.TEXT_INPUT &&
        field.key === key
      ) {
        field.value = value;
      } else if (
        field.type === ReturnSummaryInputType.DROPDOWN &&
        field.options?.includes(key)
      ) {
        field.key = key;
        field.value = value;
      }
    });
  });
  emptyIndividualTaxReturnSumary.forEach((field: any) => {
    if (field.type === ReturnSummaryInputType.DROPDOWN && field.key === null) {
      // dropdown field with empty key lets set it to first option
      field.key = field?.options?.[0] ?? null;
    }
  });
  return emptyIndividualTaxReturnSumary;
};

export const convertFrontendTaxFormToBackendTaxForm = (
  data: ReturnSummaryInputFieldProps[],
) => {
  const backendData: any = {};
  data.forEach((field) => {
    if (field.key === null) {
      return;
    }
    backendData[field.key] = field.value;
  });
  return backendData;
};

export const isValidTaxReturnForm = (data: ReturnSummaryInputFieldProps[]) => {
  return data.every((field) => {
    if (field.valueMandatory && field.value === null) {
      return false;
    }

    if (
      field.type === ReturnSummaryInputType.DROPDOWN &&
      !field.options.includes(field.key)
    ) {
      return false;
    }

    return true;
  });
};

export const getReturnSummaryType = (
  returnType: TaxReturnType,
  isState: boolean,
  businessType?: BusinessType,
) => {
  if (returnType === TaxReturnType.INDIVIDUAL) {
    return RETURN_SUMMARY_TYPE.INDIVIDUAL;
  }
  switch (businessType) {
    case BusinessType.C_CORP:
      return isState
        ? RETURN_SUMMARY_TYPE.C_CORP_STATE
        : RETURN_SUMMARY_TYPE.C_CORP_FEDERAL;
    case BusinessType.S_CORP:
      return isState
        ? RETURN_SUMMARY_TYPE.S_CORP_PARTNERSHIP_STATE
        : RETURN_SUMMARY_TYPE.S_CORP_FEDERAL;
    case BusinessType.PARTNERSHIP:
      return isState
        ? RETURN_SUMMARY_TYPE.S_CORP_PARTNERSHIP_STATE
        : RETURN_SUMMARY_TYPE.PARTNERSHIP_FEDERAL;
    default:
      return RETURN_SUMMARY_TYPE.INDIVIDUAL;
  }
};
